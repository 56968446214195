// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage"; // Add this line

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBe3hMFk9nufh94QSrXCeP3m19EIE8RoiQ",
  authDomain: "anonymussss.firebaseapp.com",
  databaseURL: "https://anonymussss-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "anonymussss",
  storageBucket: "anonymussss.appspot.com",
  messagingSenderId: "105350455117",
  appId: "1:105350455117:web:c6f2e6b81940d05da4a104",
  measurementId: "G-42EKRJZHW3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const storage = getStorage(app); // Add this line

export { app, analytics, database, storage };

