import React, { useEffect } from 'react';
import NavigationMenu from './navbar';
import styles from './Cose.module.css'; // Stili CSS importati
import Footer from './Footer';

function Cose() {

    useEffect(() => {
        const sections = document.querySelectorAll(`.${styles.section}`);
        window.addEventListener('scroll', () => {
            sections.forEach(section => {
                const rect = section.getBoundingClientRect();
                if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                    section.classList.add(styles.visible);
                } else {
                    section.classList.remove(styles.visible);
                }
            });
        });
    }, []);

    return (
        <div>
            <NavigationMenu />
            <main className={styles.mainContent}>
                <h1 className={styles.title}>Cos'è Anonymouss</h1>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>Chi siamo</h2>
                    <p>Siamo un gruppo di designer e sviluppatori con sede a Cilavegna, Lombardia. Come amici con una passione per la tecnologia, abbiamo creato Anonymouss con l'obiettivo di rivoluzionare la messaggistica anonima e abbattere i monopoli dei social esistenti su questo tipo di contenuti.</p>
                </section>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>Il nostro obiettivo</h2>
                    <p>Crediamo che le nostre amicizie online siano un'estensione del mondo reale, e non un sostituto. Le tue interazioni online con gli amici dovrebbero approfondire le tue connessioni piuttosto che allentarle. Anonymouss ti permette di conoscere meglio i tuoi amici attraverso emozionanti e intriganti giochi di domande e risposte, rafforzando le tue amicizie e aiutandoti a essere te stesso autenticamente.</p>
                </section>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>La nostra comunità</h2>
                    <p>Quando abbiamo immaginato Anonymouss, sapevamo che era qualcosa di speciale. Oggi è un'app che sta aiutando migliaia di utenti a connettersi tra loro mantenendo la sicurezza online. È chiaro che Anonymouss ha colpito nel segno, riempiendo un grande vuoto nello spazio dei social media.</p>
                </section>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>Funzionalità per l'utenza media</h2>
                    <p>Per l'utenza media, Anonymouss offre domande a cui rispondere con la possibilità di personalizzare lo stile dei bordi, il font e il colore del testo. In futuro, ci saranno nuove funzionalità come l'invio di foto anonime (in futuro anche video e audio), il che lo rende unico nel suo genere. Le domande utilizzano la crittografia end-to-end per garantire che solo il destinatario possa leggere il messaggio. Inoltre, è possibile condividere le domande anonime su più dispositivi.</p>
                </section>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>Funzionalità per i creator</h2>
                    <p>Anonymouss offre strumenti avanzati per i creator, come Insights per monitorare l'engagement e altri approfondimenti che rendono l'esperienza fluida e innovativa. Stiamo continuamente migliorando per offrire nuove funzionalità e supportare i nostri creator nella loro crescita.</p>
                </section>

              <br />
              <br />
              <br />
              <br />
              <br />
              
            </main>
            <Footer />
        </div>
    );
}

export default Cose;
