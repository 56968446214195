import React from "react";
import { motion } from 'framer-motion';
import AnimatedTexts from './Animateext';
import styles from '../css/interactiveSection.module.css';

function OltherSection() {
    return(
        <section className={styles.interactiveSection}>
        <h2 className={styles.sectionTitle}>Gioca ai minigiochi, divertiti con le domande!</h2>
        <div className={styles.content}>
          <motion.img 
            src="https://img.freepik.com/vettori-premium/quiz-in-stile-fumetto-pop-art_175838-505.jpg" 
            alt="Quiz interattivi" 
            className={`${styles.image} ${styles.fadeIn}`} 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
          <motion.img 
            src="https://media.istockphoto.com/id/693210524/it/vettoriale/pop-art-stile-fumetti-pannello-gossip-ragazza-sussurrando-segreto-in-orecchio-parola-di.jpg?s=612x612&w=0&k=20&c=P0EjZGM5WiDVpcTmszL_X2rDuYgL13Iu-A3xD6tClZs=" 
            alt="Domande anonime e gossip" 
            className={`${styles.image} ${styles.fadeIn}`} 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
          <motion.img 
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJlDduzedmi2723loaX3pQdxT3b5IwYg5BIw&s" 
            alt="Crea i tuoi audio con voce personalizzata usando l'IA" 
            className={`${styles.image} ${styles.fadeIn}`} 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
          <motion.img 
            src="https://www.unite.ai/wp-content/uploads/2023/10/Alex_Mc_video_content._colorful._vector_graphic_ee0fe716-cd7f-4f50-8140-68b83b82a0f7.jpg" 
            alt="Invia dei video anonimi" 
            className={`${styles.image} ${styles.fadeIn}`} 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
        </div>
        <AnimatedTexts />
      </section>
    );
}

export default OltherSection;




