
import React, { useState, useEffect } from 'react';
import { FaAndroid, FaApple } from 'react-icons/fa';

function Domande() {
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const images = document.querySelectorAll('.fadeIn');
    images.forEach(image => {
      image.classList.add('visible');
    });

    const handleMouseMove = (e) => {
      document.querySelectorAll('.floating-image').forEach(image => {
        const rect = image.getBoundingClientRect();
        const offsetX = (e.clientX - rect.left) / rect.width * 30 - 15;
        const offsetY = (e.clientY - rect.top) / rect.height * 30 - 15;
        image.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
      });
    };
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const styles = {
    mainContent: {
      padding: '20px',
      textAlign: 'center',
      position: 'relative',
      fontFamily: 'Arial, sans-serif'
    },
    introduction: {
      marginBottom: '20px'
    },
    details: {
      marginBottom: '20px'
    },
    imageContainer: {
      display: 'flex',
      gap: '20px',
      justifyContent: 'center'
    },
    placeholderImage: {
      width: '100%',
      maxWidth: '300px',
      opacity: '0',
      transition: 'opacity 1s ease-in-out'
    },
    fadeIn: {
      opacity: '1'
    },
    bigTitle: {
      fontFamily: '"Spicy Rice", serif',
      fontSize: '76px',
      textAlign: 'center',
      fontWeight: '400',
      fontStyle: 'normal'
    },
    mainTitle: {
      fontSize: '24px',
      marginTop: '20px'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px'
    },
    button: {
      backgroundColor: '#007bff',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      fontSize: '16px'
    },
    buttonHover: {
      backgroundColor: '#0056b3'
    },
    floatingImage: {
      position: 'absolute',
      width: '200px',
      transition: 'transform 0.2s'
    },
    leftImage: {
      top: '50%',
      left: '-250px', // Sposta le immagini al di fuori del contenuto principale
      transform: 'translateY(-50%)'
    },
    rightImage: {
      top: '50%',
      right: '-250px', // Sposta le immagini al di fuori del contenuto principale
      transform: 'translateY(-50%)'
    },
    borderedText: {
      border: '10px solid #007bff',
      padding: '100px',
      marginLeft: '600px',
      marginRight: '600px',
      borderRadius: '75px',
      display: 'inline-block',
      textAlign: 'center',
      fontSize: '18px' // Ingrandisci il font
    }
  };

  return (
    <main style={styles.mainContent}>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Spicy+Rice&display=swap');
      </style>
      <section style={styles.introduction}>
        <h1 style={styles.bigTitle}>Benvenuti su
          <br /> Anonymouss</h1>
        <div style={styles.borderedText}>
          <p>Anonymouss è il nuovo social network di nglcila che ti permette di fare domande anonime. Esprimi i tuoi dubbi, le tue curiosità e le tue idee senza rivelare la tua identità! Questa app è mirata a rivoluzionare la messaggistica, adatta anche ai creator che usufruiscono di questa funzione con funzionalità avanzate. Include la possibilità di avere le domande criptate, di sincronizzare le domande su altri dispositivi e molto altro!</p>
        </div>
        
        <h2 style={styles.mainTitle}>Cosa aspetti, scarica subito!</h2>
        <div style={styles.buttonContainer}>
          <button style={styles.button} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = styles.button.backgroundColor}>
            <FaAndroid /> <FaApple /> Download Anonymouss
          </button>
        </div>
        <img src="https://framerusercontent.com/images/1ojZwN35M1543O3qePxnuoypA9A.png" alt="Left Floating" style={{...styles.floatingImage, ...styles.leftImage}} className="floating-image" />
        <img src="https://framerusercontent.com/images/1ojZwN35M1543O3qePxnuoypA9A.png" alt="Right Floating" style={{...styles.floatingImage, ...styles.rightImage}} className="floating-image" />
      </section>

      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={handleToggleDetails} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = styles.button.backgroundColor}>
          {showDetails ? 'Nascondi dettagli' : 'Mostra dettagli'}
        </button>
      </div>

      {showDetails && (
        <section style={styles.details}>
          <h2>Come funziona</h2>
          <p>Puoi postare le tue domande in modo completamente anonimo e ricevere risposte dalla community.</p>
          <p>Anonymouss include funzionalità avanzate come la possibilità di avere le domande criptate, di sincronizzare le domande su altri dispositivi e molto altro!</p>
          <img src="https://framerusercontent.com/images/tb4WP0Q85cy7h7y19oHHr5Ps8pQ.jpg" alt="Dettagli Placeholder" className="placeholderImage fadeIn" style={{...styles.placeholderImage, ...styles.fadeIn}} />
        </section>
      )}
    </main>
  );
}

export default Domande;








