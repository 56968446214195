import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import './css/home.css';
import logo from './img/logo.jpeg';
import Navbar from './css/navbar';
import Footer from './css/Footer';
import QRCodeSection from './css/QRCodeSection';
import AnimatedTexts from './css/Animateext';
import Domande from './css/Domande';
import OltherSection from './css/OltherSection';

const Home = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const images = document.querySelectorAll('.fade-in');
    images.forEach(image => {
      image.classList.add('visible');
    });
  }, []);

  const handleDiscoverMore = () => {
    setShowDetails(true);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    
      <div className="App" style={{backgroundColor: 'black'}}>
        <div className="marquee">
          <p><strong>invia segnali anonimi, divertimento è anonimo, descrivimi in tre parole, l'amore e la gioia, le immagini di amore, domande divertenti, rispetto verso il prossimo, ngl cila, cila studios, personalizzazione delle domande</strong></p>
        </div>
        <Navbar />
        <main className="content">
          <Domande />
         <OltherSection />
          
        </main>
        <Footer />
      </div>
    
  );
};

export default Home;









