import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';
import { Theme } from "@radix-ui/themes";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  
    
  <Router>
     <Theme accentColor="crimson" grayColor="sand" radius="large" scaling="95%">
     <div className="w-screen h-screen p-8 flex items-start justify-center">
    <App />
    </div>
    </Theme>
  </Router>
  
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
