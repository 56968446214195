import React from 'react';
import Slider from 'react-slick';
import './AnimatedText.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SimpleCarousel = () => {
  const items = [
    { text: 'gioca q&a', image: 'https://framerusercontent.com/images/IqcccrhYwPEBRCzLBmmfUIB3fc.jpg' },
    { text: 'giochi', image: 'https://framerusercontent.com/images/B0kWtNVT2a4nBCP8w61uE06np8.jpg' },
    { text: 'chiedimi qualsiasi cosa', image: 'https://framerusercontent.com/images/jK60Jd0g43951FwW8jdTj0zBx8.jpg' },
    { text: 'non ho mai', image: 'https://framerusercontent.com/images/WbYNVhCzVUkbwvaM3otxHzvS7hs.jpg' },
    { text: 'confessioni', image: 'https://framerusercontent.com/images/9Zn10awOegDDlupSZubQ1vQ0c.png' },
    { text: '3 parole', image: 'https://framerusercontent.com/images/m2CGjKWQgxBHwdAztZ8z7VQubcY.png' }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: 'linear'
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index} className="carousel-slide">
            <div className="bubble-container">
              <img src={item.image} alt={`Slide ${index}`} className="carousel-image" />
              <div className="text-item">{item.text}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SimpleCarousel;




