import React, { useEffect } from 'react';
import NavigationMenu from './navbar';
import styles from './trattamentodati.css'; // Stili CSS importati

function TrattamentoDati() {

    useEffect(() => {
        const sections = document.querySelectorAll(`.${styles.section}`);
        window.addEventListener('scroll', () => {
            sections.forEach(section => {
                const rect = section.getBoundingClientRect();
                if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                    section.classList.add(styles.visible);
                } else {
                    section.classList.remove(styles.visible);
                }
            });
        });
    }, []);

    return (
        <div>
            <NavigationMenu />
            <main className={styles.mainContent}>
                <h1 className={styles.title}>Trattamento dei Dati Personali</h1>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>Introduzione</h2>
                    <p>La tua privacy è importante per noi. Questa pagina descrive in dettaglio come raccogliamo, utilizziamo, e proteggiamo i tuoi dati personali.</p>
                </section>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>Raccolta dei Dati</h2>
                    <p>Raccogliamo diversi tipi di dati, inclusi ma non limitati a:</p>
                    <ul>
                        <li>Dati di contatto (nome, indirizzo email, numero di telefono)</li>
                        <li>Informazioni di accesso (username, password)</li>
                        <li>Dati di utilizzo (interazioni con il sito, preferenze)</li>
                        <li>Contenuti generati dagli utenti (post, commenti, risposte)</li>
                    </ul>
                </section>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>Uso dei Dati</h2>
                    <p>Utilizziamo i tuoi dati per diverse finalità, tra cui:</p>
                    <ul>
                        <li>Fornire e migliorare i nostri servizi</li>
                        <li>Personalizzare la tua esperienza</li>
                        <li>Comunicare con te riguardo a aggiornamenti e promozioni</li>
                        <li>Garantire la sicurezza e prevenire frodi</li>
                    </ul>
                </section>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>Protezione dei Dati</h2>
                    <p>Adottiamo misure di sicurezza appropriate per proteggere i tuoi dati personali da accessi non autorizzati, alterazioni, divulgazioni o distruzioni. Queste misure includono:</p>
                    <ul>
                        <li>Uso di protocolli di crittografia</li>
                        <li>Accesso limitato ai dati solo al personale autorizzato</li>
                        <li>Audit e monitoraggio regolari delle nostre pratiche di sicurezza</li>
                    </ul>
                </section>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>Condivisione dei Dati</h2>
                    <p>Non vendiamo, affittiamo o condividiamo i tuoi dati personali con terze parti senza il tuo consenso, eccetto nei seguenti casi:</p>
                    <ul>
                        <li>Per adempiere a obblighi legali</li>
                        <li>Per proteggere i diritti, la proprietà o la sicurezza della nostra azienda e dei nostri utenti</li>
                        <li>Con fornitori di servizi terzi che lavorano per nostro conto</li>
                    </ul>
                </section>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>I Tuoi Diritti</h2>
                    <p>Hai il diritto di accedere, correggere, cancellare i tuoi dati personali e opporsi al loro trattamento. Per esercitare questi diritti, contattaci all'indirizzo email fornito nella sezione contatti.</p>
                </section>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>Modifiche alla Politica</h2>
                    <p>Potremmo aggiornare questa politica di trattamento dei dati di tanto in tanto. Ti informeremo di eventuali modifiche significative pubblicando l'aggiornamento sul nostro sito e, se necessario, richiedendo il tuo consenso.</p>
                </section>
                <section className={`${styles.section} ${styles.visible}`}>
                    <h2>Contatti</h2>
                    <p>Per qualsiasi domanda o preoccupazione riguardante il trattamento dei tuoi dati personali, puoi contattarci all'indirizzo email: privacy@anonymouss.com</p>
                </section>
            </main>
        </div>
    );
}

export default TrattamentoDati;


